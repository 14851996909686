'use client';
import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import lightLogo from '../../assets/images/BrandLogo.png';
import { navigationLinks } from '@/config/footerConfig';
import SocialLinks from './SocialLinks';
import CTAButton from '../commons/CTAButton';

const NewFooter = () => {
  return (
    <footer className="w-full bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Main Footer Content */}
        <div className="flex flex-col lg:flex-row justify-between items-start lg:items-start space-y-8 lg:space-y-0">
          {/* Logo and Follow Us Section */}
          <div className="space-y-4">
            <Image
              src={lightLogo}
              width={180}
              height={60}
              alt="Hackingly Logo"
              priority
              className="mb-6"
            />
            <div>
              <p className="text-sm mb-2">Follow us</p>
              <div className="flex space-x-4">
                <SocialLinks />
              </div>
            </div>
          </div>

          {/* Navigation Links */}
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-8 lg:gap-16">
            {/* Company Section */}
            <div>
              <h3 className="font-medium mb-4">Company</h3>
              <ul className="space-y-2">
                <li>
                  <Link
                    href="/about-us"
                    className="text-gray-600 hover:text-gray-900"
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    href="/events"
                    className="text-gray-600 hover:text-gray-900"
                  >
                    Events
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://chat.whatsapp.com/Kv681piogRgLxMXXqUtKCS"
                    target="_blank"
                    // rel={'norefferer nooppner'}
                    className="text-gray-600 hover:text-gray-900"
                  >
                    Community
                    <span className="ml-1 text-xs text-red-500">New</span>
                  </Link>
                </li>
                <li>
                  <Link
                    href="/jobs"
                    className="text-gray-600 hover:text-gray-900"
                  >
                    Jobs & Internship
                  </Link>
                </li>
              </ul>
            </div>

            {/* Resources Section */}
            <div>
              <h3 className="font-medium mb-4">Resources</h3>
              <ul className="space-y-2">
                <li>
                  <Link
                    href="/blog"
                    className="text-gray-600 hover:text-gray-900 flex items-center"
                  >
                    Blogs
                    <span className="ml-1 text-xs text-red-500">New</span>
                  </Link>
                </li>
                <li>
                  <Link
                    href="/faq"
                    className="text-gray-600 hover:text-gray-900"
                  >
                    FAQs
                  </Link>
                </li>
                <li>
                  <Link
                    href="/support"
                    className="text-gray-600 hover:text-gray-900"
                  >
                    Support
                  </Link>
                </li>
              </ul>
            </div>

            {/* Legal Section */}
            <div>
              <h3 className="font-medium mb-4">Legal</h3>
              <ul className="space-y-2">
                <li>
                  <Link
                    href="/terms"
                    className="text-gray-600 hover:text-gray-900"
                  >
                    Terms of Service
                  </Link>
                </li>
                <li>
                  <Link
                    href="/privacy-policy"
                    className="text-gray-600 hover:text-gray-900"
                  >
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          {/* Newsletter Section */}
          <div className="lg:max-w-md w-full">
            <h3 className="font-medium mb-4">
              Stay up to date with Hackingly!
            </h3>
            <form className="flex items-center border-gray-300 bg-white rounded-2xl overflow-hidden">
              {/* <input
                type="email"
                placeholder="Enter your email address"
                className="flex-grow px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-green-500"
              />
              <button className="px-6 py-2 bg-[#097969] text-white rounded-lg hover:bg-green-700 whitespace-nowrap">
                Subscribe
              </button> */}

              <input
                type="email"
                placeholder="Enter your email address"
                className=" flex-grow py-2 px-4 text-sm md:text-md  font-2xl font-sans rounded-2xl focus:outline-none bg-[#ffffff] min-w-[200px]"
              />
              {/* <Button
        type="submit"
        className="md:py-7 md:px-14 text-sm md:text-lg shadow-inner-glow bg-[#097969] shadow-2xl text-white md:rounded-2xl hover:bg-green-700 whitespace-nowrap"
      > */}
              <CTAButton>Subscribe</CTAButton>
            </form>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="mt-12 pt-8 border-t border-gray-200">
          <div className="flex flex-col lg:flex-row justify-between items-center space-y-4 lg:space-y-0">
            <div>
              <h3 className="font-medium text-lg">
                Collaborate With Hackingly
              </h3>
              <p className="text-gray-600">
                Empower Talent To Transform Ideas Into Impactful Innovations
              </p>
            </div>
            <div className="text-center lg:text-right text-sm text-gray-600">
              <p>© 2024 Hackingly™. All Rights Reserved.</p>
              <p>
                Crafted with <span className="text-red-500">❤</span> by Giby
                Technologies Private Limited
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default NewFooter;
