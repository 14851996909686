'use client';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Eye, EyeOff } from 'lucide-react';
import { baseURL } from '@/utils/BaseURL';
import axios from 'axios';
import toast from 'react-hot-toast';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from '@nextui-org/react';
import './../../../app/globals.css';

const NewResetPassword = ({ isModalOpen, closeModal, onGoBackToLogin }) => {
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [canRequestOTP, setCanRequestOTP] = useState(true);
  const [timeLeft, setTimeLeft] = useState(0);
  const [isVisible1, setIsVisible1] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);

  const {
    register: emailRegister,
    getValues: getEmail,
    handleSubmit: handleEmailSubmit,
    setError: setEmailError,
    formState: { errors: emailErrors },
    reset: resetEmailForm,
  } = useForm();

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    let interval;
    if (timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft(prev => (prev > 0 ? prev - 1 : 0));
      }, 1000);
    } else {
      setCanRequestOTP(true);
    }
    return () => clearInterval(interval);
  }, [timeLeft]);

  const handleRequestOTPTimer = () => {
    setCanRequestOTP(false);
    setTimeLeft(60);
  };

  const toggleVisibility1 = () => setIsVisible1(!isVisible1);
  const toggleVisibility2 = () => setIsVisible2(!isVisible2);

  const handleChangeEmail = () => {
    setIsOtpSent(false);
    reset();
    resetEmailForm();
    setTimeLeft(0);
    setCanRequestOTP(true);
  };

  const onEmailSubmit = async data => {
    const sendingToast = toast.loading('Sending OTP...');

    if(!getEmail('email')){
      toast.error('Something went wrong.\nPlease try again');
      resetEmailForm();
      setIsOtpSent(false);
      // reset();
      setTimeLeft(0);
      setCanRequestOTP(true);
      return;
    }
    const email =getEmail('email');
    try {
      const response = await axios.post(
        `${baseURL}/users/forgotpassword/`,
        { email },
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (response.status === 201) {
        toast.success('OTP Sent!', { id: sendingToast });
        // reset();
        handleRequestOTPTimer();
        setIsOtpSent(true);
        setValue('email', getEmail('email'));
      }
    } catch (err) {
      toast.dismiss(sendingToast);
      if (err.response?.status === 404) {
        toast.error('Email not found');
        setEmailError('email', { message: 'Email not found' });
      } else {
        toast.error('Failed to send OTP. Please try again.');
      }
    }
  };

  const onSubmit = async data => {
    const {email, otp,password, confirmPassword} =data
    if (password !== confirmPassword) {
      setError('confirmPassword', { message: 'Passwords do not match' });
      return;
    }

    const verifyingToast = toast.loading('Verifying Credentials...');
    try {
     

      const response = await axios.post(
        `${baseURL}/users/forgotpasswordotp/`,

        {
          email,
          otp,
          password,
        },
        { headers: { 'Content-Type': 'application/json' } }
      );
      if (response.status === 200) {
        toast.success('Password reset successful!', { id: verifyingToast });
        closeModal();
      }
          }
     catch (err) {
      toast.dismiss(verifyingToast);
      if (err.response?.data?.errors === 'Invalid OTP') {
        setError('otp', { message: 'Invalid OTP' });
        toast.error('Invalid OTP!');
      } else if (err.response?.data?.errors === 'OTP not found in cache') {
        toast.error('OTP Expired!');
        closeModal();
      } else {
        toast.error('Failed to reset password. Please try again.');
      }
    }
  };

  const handleGoBack = () => {
    handleChangeEmail();
    closeModal();
    onGoBackToLogin();
  };

  return (
    <Modal backdrop="blur" isOpen={isModalOpen} onClose={closeModal} size="lg">
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">
          {isOtpSent ? 'Reset Your Password' : 'Forgot Password'}
        </ModalHeader>
        <ModalBody>
          {!isOtpSent ? (
            <form
              onSubmit={handleEmailSubmit(onEmailSubmit)}
              className="space-y-4"
            >
              <Input
                label="Email"
                variant="bordered"
                {...emailRegister('email', { required: 'Email is required' })}
                isInvalid={!!emailErrors.email}
                errorMessage={emailErrors.email?.message}
              />
              <Button
                className="bg-[#097969] font-semibold text-white shadow-custom-inner-2 shadow-custom-inner-1 transition duration-200"
                type="submit"
                fullWidth
              >
                Request OTP
              </Button>
            </form>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
              <Input
                label="Email"
                variant="bordered"
                // isDisabled
                isReadOnly
                defaultValue={getEmail('email')}
                {...register('email')}
              />
              <Input
                label="OTP"
                variant="bordered"
                {...register('otp', { required: 'OTP is required' })}
                isInvalid={!!errors.otp}
                errorMessage={errors.otp?.message}
              />
              <Input
                label="New Password"
                variant="bordered"
                type={isVisible1 ? 'text' : 'password'}
                {...register('password', { required: 'Password is required' })}
                isInvalid={!!errors.password}
                errorMessage={errors.password?.message}
                endContent={
                  <button
                    className="focus:outline-none"
                    type="button"
                    onClick={toggleVisibility1}
                  >
                    {isVisible1 ? (
                      <EyeOff className="text-default-400" />
                    ) : (
                      <Eye className="text-default-400" />
                    )}
                  </button>
                }
              />
              <Input
                label="Confirm Password"
                variant="bordered"
                type={isVisible2 ? 'text' : 'password'}
                {...register('confirmPassword', {
                  required: 'Please confirm your password',
                })}
                isInvalid={!!errors.confirmPassword}
                errorMessage={errors.confirmPassword?.message}
                endContent={
                  <button
                    className="focus:outline-none"
                    type="button"
                    onClick={toggleVisibility2}
                  >
                    {isVisible2 ? (
                      <EyeOff className="text-default-400" />
                    ) : (
                      <Eye className="text-default-400" />
                    )}
                  </button>
                }
              />
              <div className="flex justify-between text-sm">
                <button
                  type="button"
                  className="text-[#097969] "
                  onClick={handleChangeEmail}
                >
                  Change Email
                </button>
                <button
                  type="button"
                  className={`${
                    canRequestOTP ? 'text-[#097969]' : 'text-gray-400'
                  } hover:underline`}
                  onClick={() => {
                    if (canRequestOTP) {
                      // reset()
                      onEmailSubmit();
                    }
                  }}
                  disabled={!canRequestOTP}
                >
                  Resend OTP {!canRequestOTP && `(${timeLeft}s)`}
                </button>
              </div>
              <Button
                className="shadow-custom-inner-2 shadow-custom-inner-1 transition duration-200"
                type="submit"
                fullWidth
              >
                Reset Password
              </Button>
            </form>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant="flat" onPress={handleGoBack} fullWidth>
            Go Back to Login
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NewResetPassword;